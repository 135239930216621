import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import HeaderImage from 'components/header/header-image/headerImage.js';

const Training = ({ data }) => {
  const items = data.allTrainingJson.edges[0].node;
  // const training = data.allTrainingJson.edges[0].node.items.map(item => {
  //   return (
  //     <div className="training-page__item">
  //       <div className="training-page__icon">
  //         <img src={item.icon} alt="ikona szkolenia"/>
  //       </div>
  //       <div className="training-page__content">
  //       <h4>{item.title}</h4>
  //       <p className="mt-5">{item.description}</p>
  //       </div>
  //     </div>
  //   );
  // });

  return (
    <Layout buttonClass="white">
      <HeaderImage title="SZKOLENIA" />
      <section className="training-page">
        <div className="container">
          <div className="row">
            <div className="training-page__about">
              <h3>{items.title}</h3>
              <h4 className="mt-9">{items.headline}</h4>
            </div>
            <div className="training-page__items mt-8">
              <div className="training-page__item">
                <div className="training-page__icon">
                  <img
                    src="pictures/framed-pictures/002-hotel.svg"
                    alt="ikona szkolenia"
                  />
                </div>
                <div className="training-page__content">
                  <h4>PRAWA NIERUCHOMOŚCI</h4>
                  <p className="mt-5">
                    Szkolenia obejmują wybrane zagadnienia związane z{' '}
                    <strong>
                      prawnymi zasadami obrotu i zarządem nieruchomościami.
                    </strong>{' '}
                     Dotyczą zasad <strong>zawierania umów
                    przedwstępnych czy konkretnych rodzajów umów takich jak
                    sprzedaż nieruchomości czy umowy dożywocia lub darowizny. </strong> 
                     Ponadto przedmiotem szkolenia mogą być zasady najmu, ustawy
                    o ochronie praw lokatorów, wspólnotach mieszkaniowych czy
                    zagadnienia z zakresu ustawy o gospodarce nieruchomościami.
                    Szkolimy także z zasad <strong> przekształcenia prawa użytkowania
                    wieczystego we własność,</strong>  procedur <strong> zasiadywania nieruchomości
                    czy znoszenia współwłasności oraz ksiąg wieczystych i
                    hipotek </strong>  (oraz innych form zabezpieczeń).
                  </p>
                </div>
              </div>
              <div className="training-page__item">
                <div className="training-page__icon">
                  <img
                    src="pictures/framed-pictures/001-pantheon.svg"
                    alt="ikona szkolenia"
                  />
                </div>
                <div className="training-page__content">
                  <h4>PRAWA CYWILNEGO</h4>
                  <p className="mt-5">
                    Oferujemy szeroki wachlarz szkoleń z zakresu prawa
                    cywilnego. Zarówno <strong>prawa umów</strong>  (najem, roboty budowlane,
                    zlecenie/dzieło) jak i <strong> prawa spadkowego </strong> (postępowania
                    spadkowe, praw spadkowe a obrót nieruchomościami). Ponadto
                    przygotowujemy szkolenia z zakresu prawa rzeczowego
                    (własność i in.) jak i <strong> postępowania egzekucyjnego i
                    windykacji </strong>  należności. <strong> Prowadzimy szkolenia z podstaw prawa
                    cywilnego i gospodarczego dla urzędników.</strong> 
                  </p>
                </div>
              </div>
              <div className="training-page__item">
                <div className="training-page__icon">
                  <img
                    src="pictures/framed-pictures/003-auction.svg"
                    alt="ikona szkolenia"
                  />
                </div>
                <div className="training-page__content">
                  <h4>PPRAWA ADMINISTRACYJNEGO I SĄDOWOADMINISTRACYJNEGO</h4>
                  <p className="mt-5">
                    <strong>Nasze szkolenia skierowane są dla instytucji publicznych. </strong>
                    Mogą obejmować zarówno tematykę prawa administracyjnego
                    (m.in. prawo budowlane, ustawa o gospodarce nieruchomościami
                    i in.) jak i postepowanie administracyjne (przed organem I i
                    II instancji/samorządowymi kolegiami odwoławczymi) jak i
                    sądowoadministracyjne.
                  </p>
                </div>
              </div>
              <div className="training-page__item">
                <div className="training-page__icon">
                  <img
                    src="pictures/framed-pictures/008-auction-1.svg"
                    alt="ikona szkolenia"
                  />
                </div>
                <div className="training-page__content">
                  <h4>PRAWA AUTORSKIEGO I PRASOWEGO</h4>
                  <p className="mt-5">
                    Dzięki szkoleniu uczestnicy dowiedzą się jak prawidłowo
                    rozumieć pojęcia i definicje prawa autorskiego. Ponadto
                    podczas szkolenia omawiane są <strong> zasady przygotowywania i
                    prawidłowej treści umów,</strong>  w tym pól eksploatacji. Omawiane są
                    także prawne możliwości działań w sytuacji naruszenia praw
                    autorskich a także <strong> dóbr osobistych i prawa do wizerunku. </strong> 
                    Ponadto szkolenie może obejmować tematykę <strong> prawa prasowego.</strong>
                  </p>
                </div>
              </div>
              <div className="training-page__item">
                <div className="training-page__icon">
                  <img
                    src="pictures/framed-pictures/012-info.svg"
                    alt="ikona szkolenia"
                  />
                </div>
                <div className="training-page__content">
                  <h4>DOSTĘPU DO INFORMACJI PUBLICZNEJ</h4>
                  <p className="mt-5">
                    <strong>  Szkolenie skierowane jest do instytucji publicznych. </strong>
                    Omawiane są w jego ramach zagadnienia dostępu do informacji
                    publicznej takie jak czym jest informacja publiczna, czym
                    informacja przetworzona, jak należy prawidłowo odmawiać.
                   <strong> Prezentowane są konkretne przykłady jak formułować pisma
                    (odpowiedzi) i decyzje.</strong>  Podczas szkolenie prezentowane jest
                    także czym jest informacja sektora publicznego i jakie są
                    zasady jej ponownego wykorzystania.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row why mt-8">
            <div className="container">
              <div className="training-page__why mt-6">
                <h3>DLACZEGO WARTO?</h3>
                <p className="mt-8">
                  Zapoznanie się z kluczowymi zagadnieniami z zakresu przepisów
                  prawa, ze szczególnym uwzględnieniem ich praktycznego
                  zastosowania w bieżącej pracy, w tym najnowszymi zmianami w
                  prawie.
                </p>
                <p className="mt-8">
                  Umiejętność praktycznego wykorzystania zdobytej wiedzy.
                </p>
                <p className="mt-8">
                  Otrzymanie przydatnych orzeczeń sądowych oraz wzorów.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

Training.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Training;

export const query = graphql`
  query trainingQuery {
    allTrainingJson {
      edges {
        node {
          headline
          title
          items {
            icon
            title
            description
          }
        }
      }
    }
  }
`;
